/**
*   @name: header.scss
*   @description: Estilos del header
*/

header {
    padding: $paddingMedium 0;
    background-color: $white;

    nav {
        display: flex;
        justify-content: flex-end;
        .nav-item {
            padding: 0 $paddingNormal;
        }
        .nav-active {
            color: $primary;
        }

    }

}


@media screen and (max-width: 768px) {
    
    header {
        .header {
            padding: $paddingMedium;
            .logo {
                width: 100%;
                justify-content: center;
            }
        }
        nav {
            width: 100%;
            justify-content: center;
            .nav-item {
                margin-top: $marginMedium;
                padding: 0 $paddingMedium;
                &:last-child { padding-right: $paddingNormal; } 
                a { font-size: $fontTiny; }
            }        
        }
    }
}
