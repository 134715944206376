/**
*   @name: cards.scss
*   @description: Estilos de los componentes de tarjeta
*/

.card-vehicle {
    width: 33%;
    margin: $marginNormal;
    border-radius: 4px;
    border: 3px solid #ebebeb;
    transition: 250ms ease-in-out;
    &:hover { border: 3px solid #e8d136; }
    &:first-child { margin-left: 0; }
    &:last-child { margin-right: 0; }
    .image {
        border-radius: 4px 4px 0 0;
        img {
            min-height: 200px;
        }
    }
    .description {
        flex-grow: 1;
        background-color: $white;
    }
    .type {
        padding: $paddingNormal 12px;
        border-top: 0;
        h3 {
            padding-left: $paddingNormal;
        }
    }
    .characteristics {
        padding: $paddingNormal $paddingMedium;
        .icon {
            margin-right: 4px;
            img {
                width: 32px;
                height: 32px;
            }
        }
    }
    button {
        padding: $paddingNormal $paddingMedium;
    }
}

.active-vehicle {
    border: 3px solid #e8d136;
}

.card-vehicle-empty {
    width: 33%;
    margin: $marginNormal;
    border-radius: 0;
    border: 0px;
    &:hover {
        border: 0;
    }
}

@media screen and (max-width: 1200px) {
    .card-vehicle {
        .type {
            padding: $paddingNormal 12px;
            border-top: 0;
            h3 {
                padding-left: $paddingNormal;
                font-size: $fontRegular;
            }
        }
        .characteristics {
            padding: $paddingNormal $paddingMedium;
            .icon {
                margin-right: 4px;
                img {
                    width: 32px;
                    height: 32px;
                }
            }
        }
        button {
            padding: $paddingNormal $paddingMedium;
        }
    } 
}

@media screen and (max-width: 768px) {

    .card-vehicle {
        width: 100%;
        margin: 4px 0;
        .image {
            border-radius: 4px 4px 0 0;
            img {
                width: 100%;
                min-height: auto;
            }
        }
    }   

}