/**
*   @name: inputs.scss
*   @description: Estilos de los campos de entrada de información
*/
button { cursor: pointer; }

.btn {    
    color: $white;
    font-family: $Poppins;
    padding: $paddingMedium;
    font-weight: $weightSemi;
    &:focus {
        transform: scale(0.95);
    }
}

.btn-primary { 
    background-color: $primary; 
    transition: background-color 300ms ease-in-out;
    &:hover {
        background-color: rgba($primary, 0.8);
    }
}

.btn-secondary { background-color: $secondary; }

.btn-transparent { background-color: transparent; }

.btn-radius { border-radius: 4px; }

.login {
    .btn-container {
        .btn {
            min-width: 254px;
        }
    }
}

@media screen and (max-width: 768px) {
    .hotels {
        .options {
            .btn-container {
                display: flex;
                justify-content: center;
            }
        }
    }
}